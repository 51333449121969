
import { defineComponent, ref, onMounted, onBeforeMount } from 'vue';
import axios, { AxiosError } from 'axios';
import useAlert from "@/composables/Alert"
import router from '@/router';
import {
  CampHeader,
  CampFormHeader,
  CampFormRackSubmitBtn,
} from '@/components';
import { useLoaderStore } from "@/store/LoaderStore";
import { useCompanyStore } from '@/store/CompanyStore';

interface ICategoryData {
  id?: number,
  name: string,
}

interface ICategoryResponse {
  data: {
    error: boolean,
    message: string,
    data: ICategoryData
    errorCode: string
  },
  status: number
}

function checkEmptyFields(obj: ICategoryData): boolean {
  for (let key in obj) {
    if (obj[key] === "" || obj[key] === 0) {
      return true;
    }
  }
  return false;
}

export default defineComponent({
  name: "CategoryPost",
  components: {
    CampHeader,
    CampFormHeader,
    CampFormRackSubmitBtn,
  },
  setup() {
    const initData: ICategoryData = {
      name: ""
    }
    const postObject = ref<{
      isLoading: boolean,
      noError: boolean,
      data:  null | ICategoryData
    }>({
      isLoading: false,
      noError: true,
      data: { ...initData }
    })
    const loaderStore = useLoaderStore();
    const companies = ref()
    const companyStore = useCompanyStore()

    const { showTimeAlert } = useAlert()

    async function getCompanyList() {
      try {
        const response = await axios.get('/api/getCompany')
        companies.value = response.data.data
      } catch (error) {
        
      }
    }

    /** Warning of unfilled fields */
    const isRequiredField = ref(false)

    async function onSubmitForm() {
      isRequiredField.value = false
      const { data } = postObject.value
      if(!data) {
        return showTimeAlert("Algo deu errado!", "error")
      }
      if(checkEmptyFields(data)) {
        return isRequiredField.value = true
      }
      loaderStore.open()
      try {
        const { data } = postObject.value
        if(data) {
          const { name } = data
          const result: ICategoryResponse = await axios.post(`/api/postCategory`, { name })
            showTimeAlert("Registro criado com sucesso!")
            postObject.value.data = { ...initData }
          return router.push("/categorias")
        }
      } catch (error) {
        if(error instanceof AxiosError) {
            showTimeAlert(error.response?.data.message, "error")
        } else {
          showTimeAlert("Algo deu errado!", "error")
        }
        loaderStore.close()
      }
    }

    onBeforeMount(async () => {
      await getCompanyList()
    })

    return {
      postObject,
      isRequiredField,
      onSubmitForm,
      companies,
    }
  }
})
